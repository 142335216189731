import React from 'react';
import { useHistory } from 'react-router-dom';
import Tick from '../../assets/images/Tick.png';
// import tickLottie from '../assets/images/tick-lottie.json';

const ConfirmDialog = ({ successMessage, setShowModal }) => {
    const history = useHistory();
    const handleGoHome = () => {
        setTimeout(() => {
            setShowModal(false);
            history.push('/');
        }, 0);
    };
    return (
        <div className="modal fade show confirm-modal-div" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content confirm-modal">
                    <div className="modal-body confirm-modal-body">
                        {/* <div className="lottie-container">
                            <lottie-player
                                src={tickLottie}
                                background="transparent"
                                speed="1"
                                // style={{ width: '150px', height: '150px' }}
                                autoplay
                            ></lottie-player>
                        </div> */}
                        <div className='img-container'>
                            <img src={Tick} alt='confirm icon' />
                        </div>
                        <h5 className="modal-title">Thank You!</h5>
                        <p className="modal-message">{successMessage}</p>
                        <div className='confirm-btn-div'>
                            <button type="button" className="btn btn-primary" onClick={handleGoHome}>Go Home</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmDialog;