import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './shared/Navbar';
import Footer from './shared/Footer';
import linkedin from "../assets/images/footer/linkedin.png";
import TEAM_DATA from './shared/TeamData';

const TeamData = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id') || null;

  const [teamData, setTeamData] = useState(null);

  useEffect(() => {
    if (id) {
      const foundTeamData = TEAM_DATA.find(teamData => teamData.id === id);
      setTeamData(foundTeamData);
    }
  }, [id]);

  return (
    <div className="main">
      <div className="container custom-container">
        <Navbar pageName={"about"} />
        <section className="team-main-section">
          <div className="row">
            <div className="col-md-7 content-div">
              <div className="header-div">
                <div className="header-with-icon">
                  <h2>{teamData?.name}</h2>
                  <a href={teamData?.linkedin} target="_blank" rel="noopener noreferrer" className="linkedin-link">
                    <img src={linkedin} alt="linkedin" />
                  </a>
                </div>
                <h4>{teamData?.role}</h4>
              </div>
              <p>{teamData?.mainPara}</p>
            </div>
            <div className="col-md-5 img-div">
              <img className="hero-image" src={teamData?.image} alt="Hero" />
            </div>
          </div>
        </section>
        <section className="team-content-section">
          {teamData?.otherDetails.map((item, index) => (
            <p className={index === 0 ? "quote" : ""}>{item}</p>
          ))}
          <div className='back-button-div'>
            <a href="/our-team" type="button" className="btn btn-primary" >Back</a>
          </div>
        </section>
        <Footer />
      </div>
      <div className='linear-grade1'></div>
      <div className='linear-grade2'></div>
      <div className='linear-grade3'></div>
      <div className='linear-grade4'></div>
    </div>
  );
};

export default TeamData;