import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import ContactUs from './components/ContactUs';
import Luminary from './components/LuminaryData';
import TeamData from './components/TeamData';
import AddBlog from './components/AddBlog';
import BlogData from './components/BlogData';

// Pages
const Home = lazy(() => import('./pages/Home'));
const Offerings = lazy(() => import('./pages/Offerings'));
const News=lazy(() => import('./pages/News'));
const Luminaries=lazy(() => import('./pages/Luminaries'));
const About=lazy(() => import('./pages/About'));
const JoinUs=lazy(() => import('./pages/JoinUs'));


const Routes = (props) => {
    return (
        <Switch>
            <Route path="/" exact>
                <Home />
            </Route>
            <Route path="/offerings" exact>
                <Offerings />
            </Route>
            <Route path="/news" exact>
                <News />
            </Route>
            <Route path="/luminaries" exact>
                <Luminaries />
            </Route>
            <Route path="/luminary" exact>
                <Luminary />
            </Route>
            <Route path="/our-team" exact>
                <About />
            </Route>
            <Route path="/team" exact>
                <TeamData />
            </Route>
            <Route path="/join-us" exact>
                <JoinUs />
            </Route>
            <Route path="/contact-us" exact>
                <ContactUs />
            </Route>
            <Route path="/add-blog" exact>
                <AddBlog />
            </Route>
            <Route path="/blog-data" exact>
                <BlogData />
            </Route>
        </Switch>
    );
};

export default Routes;