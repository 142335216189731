import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './shared/Navbar';
import Footer from './shared/Footer';
import BLOGS from './shared/Blogs';
import BlogData1 from './BlogData/BlogData1';
import BlogData2 from './BlogData/BlogData2';
import BlogData3 from './BlogData/BlogData3';
import BlogData4 from './BlogData/BlogData4';
import BlogData5 from './BlogData/BlogData5';
import BlogData6 from './BlogData/BlogData6';

const BlogData = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id') || null;

  const [blog, setBlog] = useState(null);

  useEffect(() => {
    if (id) {
      const data = BLOGS.find(blog => blog.id === id);
      setBlog(data);
    }
  }, [id]);

  return (
    <div className="main">
      <div className="container custom-container">
        <Navbar pageName={"news"} />
        <section className="blog-title-section" style={{ backgroundImage: `url(${blog?.image})` }}>
          <div className="heading-div">
            <h1>{blog?.title}</h1>
          </div>
        </section>
        { id === "1" ? <BlogData1/> : null }
        { id === "2" ? <BlogData2/> : null }
        { id === "3" ? <BlogData3/> : null }
        { id === "4" ? <BlogData4/> : null }
        { id === "5" ? <BlogData5/> : null }
        { id === "6" ? <BlogData6/> : null }
        <Footer />
      </div>
      <div className='linear-grade1'></div>
      <div className='linear-grade2'></div>
      <div className='linear-grade3'></div>
      <div className='linear-grade4'></div>
    </div>
  );
};

export default BlogData;