import React from 'react';
import logoFooter from "../../assets/images/footer/logo-footer.png"
import insta from "../../assets/images/footer/insta.png";
import linkedin from "../../assets/images/footer/linkedin.png";
import mail from "../../assets/images/footer/mail.png";
import twitter from "../../assets/images/footer/twitter.png";
import youtube from "../../assets/images/footer/youtube.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <a href="/"><img src={logoFooter} alt="KOVEXA" /></a>
        </div>
        <div className="footer-columns">
          <div className="footer-col">
            <h5>Thinking</h5>
            <ul>
              <li><a href="/news">News</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h5>About</h5>
            <ul>
              <li><a href="/our-team">Our Team</a></li>
              <li><a href="/luminaries">Luminaries</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h5>Solutions</h5>
            <ul>
              <li><a href="/offerings">Offerings</a></li>
              <li><a href="/contact-us">Contact Us</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h5>Connect</h5>
            <div className="social-icons">
              <a href="mailto:ks@kovexa.com"><img src={mail} alt="mail" className="footer_image_mail" /></a>
              <a href="https://www.instagram.com/kovexaedu" target='_blank' rel="noreferrer"><img src={insta} alt="insta" className="footer_image" /></a>
              <a href="https://twitter.com/andrewleeko" target='_blank' rel="noreferrer"><img src={twitter} alt="twitter" className="footer_image" /></a>
              <a href="https://www.linkedin.com/company/kovexa" target='_blank' rel="noreferrer"><img src={linkedin} alt="linkedin" className="footer_image" /></a>
              <a href="https://www.youtube.com/channel/UCc6JWc8F6TUP2zRJftQTzDw" target='_blank' rel="noreferrer"><img src={youtube} alt="youtube" className="footer_image_youtube" /></a>
            </div>
          </div>
        </div>
      </div>
      <p className="copyright">{new Date().getFullYear()} © KOVEXA - ALL RIGHTS RESERVED</p>
    </footer>

  );
};

export default Footer;