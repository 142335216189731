import hero1 from '../../assets/images/about/hero1.jpeg';
import andrew from '../../assets/images/about/Andrew clean.png';
import edward from '../../assets/images/about/Ed clean.png';
import saurav from '../../assets/images/about/Saurav clean.png';
import cristina from '../../assets/images/about/Cristina clean.png';
import nina from '../../assets/images/about/Nina clean.png';
import katie from '../../assets/images/about/Katie clean.png';

const TEAM_DATA = [
    {   
        id: "andrew-ko", 
        name: "Andrew Ko", 
        role: "Founder", 
        linkedin: "https://www.linkedin.com/in/andrewko/",
        image: andrew, 
        mainPara: "Meet Andrew Ko, the visionary Founder of Kovexa. With over 25 years in the tech industry, he has a wealth of experience in harnessing technology to drive innovation and transformation. His career took a pivotal turn during the pandemic when he observed the challenges his son faced with distance learning under an Individualized Education Program (IEP). This personal experience ignited his passion for addressing educational disparities, leading to the founding of Kovexa.",
        otherDetails: [
            "Transforming education through technology is not just a mission; it's a personal commitment. At Kovexa, we strive to bridge educational gaps and empower learners globally.",
            // "Meet Andrew Ko, the visionary Founder of Kovexa. With over 25 years in the tech industry, he has a wealth of experience in harnessing technology to drive innovation and transformation. His career took a pivotal turn during the pandemic when he observed the challenges his son faced with distance learning under an Individualized Education Program (IEP). This personal experience ignited his passion for addressing educational disparities, leading to the founding of Kovexa.",
            "Andrew's 'Jerry Maguire' moment compelled him to leave a successful career and create a company dedicated to leveraging technology for equitable education. Under his leadership, Kovexa has formed partnerships with over 200 institutions globally, impacting tens of thousands of students. His collaboration with The World Bank has championed digital skilling platforms, empowering citizens across the MENA region.",
            "Andrew has honed his skills in business development, consulting, and corporate restructuring at some of the world's leading management consulting and technology firms, including Amazon, Microsoft, and Samsung. His diverse industry experience spans education, automotive, banking, defense, counterterrorism, and health and human services. This extensive background equips him with the expertise to drive impactful change in education.",
            "At Kovexa, Andrew's hands-on approach and market expertise enable the company to deliver tangible results. Kovexa focuses on driving change in education and the workforce, offering an AI lab for education that includes AI training, co-designing of AI solutions, and program evaluation of edtech solutions. The company is also developing a safe and secure AI platform for school districts.",
            "His commitment to education reform is further demonstrated by his previous appointment to the Virginia State Board of Education and his role as Chief Innovation Officer at AASA. He holds a B.S. in Accounting from the University of Maryland and is a Certified Public Accountant.",
            "Beyond his professional achievements, he mentors young entrepreneurs and explores innovative business ideas. He cherishes his role as a loving husband and father to a daughter and two sons, drawing inspiration from his family to continue his mission of transforming education through technology."
        ] 
    },
    {   
        id: "edward-dieterle", 
        name: "Edward Dieterle, Ed.D.", 
        role: "Chief Learning Scientist", 
        linkedin: "https://www.linkedin.com/in/eddieterle/",
        image: edward, 
        mainPara: "Edward Dieterle's nearly 30-year dedication to education and the social sciences has led him to become the Chief Learning Scientist at Kovexa. He has consistently demonstrated a commitment to enhancing opportunities and well-being for learners and workers through innovative strategies and rigorous research and development.",
        otherDetails: [
            "The most dangerous experiment we can perform is to keep our current systems of schooling in place. Over time, the disconnect between what our society needs and what industrial-age educational models can provide is widening.",
            // "Edward Dieterle's nearly 30-year dedication to education and the social sciences has led him to become the Chief Learning Scientist at Kovexa. He has consistently demonstrated a commitment to enhancing opportunities and well-being for learners and workers through innovative strategies and rigorous research and development.",
            "Ed has held executive roles in both for-profit and non-profit organizations throughout his career. He served as an Executive Director at ETS, a Senior Research Officer at the Bill & Melinda Gates Foundation, and began his career as a high school chemistry teacher in Prince George's County, Maryland. Currently, Ed is a visiting scholar at Teachers College, Columbia University, investigating how people learn with educational technologies. He holds a doctorate in Learning and Teaching from Harvard University, a master's degree in science in Technology for Educators from Johns Hopkins University, and a bachelor's degree in Chemistry from Virginia Tech. With an unwavering commitment to advancing the field of education, Ed relentlessly pursues the improvement of educational outcomes through research and innovation.",
            "Ed's career includes serving as a member of the National Academy of Sciences Committee on Improving Learning with Informational Technology, holding a Carnegie Fellow in Secondary Education, and an Edward J. Meade Policy Fellow. He has also received the Jacobs Foundation Dissertation Grant. These experiences highlight his significant contributions to educational research and policy development.",
            "He believes that all people can learn just about anything given enough time, motivation, and feedback. His extroverted and adaptive nature enables him to engage effectively with all members of the education ecosystem, fostering collaborations that lead to meaningful educational innovations. Ed's inquisitive mind and dedication to continuous learning ensure that his contributions remain impactful and forward-thinking.",
            "Beyond his professional achievements, Ed is a devoted family man deeply invested in the Washington, DC, community. He, his wife, and their two teenage boys call our nation’s capital home. Both of Ed's children attend DC Public Schools, giving him a personal stake in the district's success. Throughout their time in DCPS, Ed has actively participated in multiple Local School Advisory Teams, demonstrating his commitment to improving education from within the system. This personal connection fuels his passion for driving change in education, as he understands firsthand the importance of quality educational opportunities for all students in the nation's capital and beyond."
        ] 
    },
    {   
        id: "saurav-bandi", 
        name: "Saurav Bandi", 
        role: "Solutions Architect - Cloud/AI/XR", 
        linkedin: "https://www.linkedin.com/in/saurav-bandi-7701bb154/",
        image: saurav, 
        mainPara: "Saurav Bandi brings a unique blend of technical expertise and entrepreneurial spirit to Kovexa as the Solutions Architect. His journey in the tech industry began in college when he founded his own training company, successfully educating over 3,000 students in various technologies. This early achievement showcased his ability to lead and innovate, setting the stage for a remarkable career.",
        otherDetails: [
            "I'm thrilled to be part of Kovexa because it aligns perfectly with my vision to leverage AI's potential in education. Here, I anticipate forging invaluable connections and gaining insights from seasoned tech professionals. Collaborating with them promises not only professional growth but also personal development. By uniting our efforts, we aim to harness AI's power to revolutionize K-12 education and drive meaningful change.",
            // "Saurav Bandi brings a unique blend of technical expertise and entrepreneurial spirit to Kovexa as the Solutions Architect. His journey in the tech industry began in college when he founded his own training company, successfully educating over 3,000 students in various technologies. This early achievement showcased his ability to lead and innovate, setting the stage for a remarkable career.",
            "During the pandemic, Saurav developed a COVID-19 tracking system within 30 days, earning state department recognition. This system was utilized by the police department and significantly contributed to saving many lives, highlighting his ability to create impactful solutions under pressure.",
            "Saurav's technical prowess is further exemplified by his work in Augmented Reality (AR). He architected one of the most complex AR engines, achieving an 80% reduction in costs and a 90% reduction in time and effort required for generating AR experiences. His innovative approach has set new benchmarks in the industry.",
            "In addition to his AR achievements, he has provided consulting and designed custom software solutions for various clients, resulting in substantial sales growth across all companies involved. His ability to understand client needs and deliver tailored solutions has made him a valuable asset to every project he undertakes.",
            "Saurav's academic accomplishments include a collaboration with the National Science Foundation (NSF) during his master's studies, resulting in a published research paper on the efficacy of Virtual Reality (VR) in education. Completing this paper in just a year and four months was a significant milestone in his academic journey.",
            "Beyond his professional achievements, he is a passionate soccer enthusiast, proudly sporting a tattoo of his favorite club, Chelsea, from London. He enjoys traveling for both pleasure and work, constantly seeking new experiences and broadening his horizons. Known for his spontaneous fun, Saurav loves hosting impromptu parties and game nights, creating cherished memories with friends and colleagues."
        ] 
    },
    {   
        id: "cristina-won", 
        name: "Cristina Won", 
        role: "VP of Operations", 
        linkedin: "https://www.linkedin.com/in/cristina-won/",
        image: cristina, 
        mainPara: "Cristina’s journey to becoming the VP of Operations at Kovexa is marked by her remarkable transition from the fashion industry to the education sector, driven by a passion for making a meaningful impact. Her career, spanning over 18 years in the fashion industry, included working with hip-hop legends, showcasing her ability to thrive in creative and dynamic environments.",
        otherDetails: [
            "I've always been blessed to be around creative individuals. The passion and impact of every member of Kovexa has been inspiring not only at work but in life.",
            // "Cristina’s journey to becoming the VP of Operations at Kovexa is marked by her remarkable transition from the fashion industry to the education sector, driven by a passion for making a meaningful impact. Her career, spanning over 18 years in the fashion industry, included working with hip-hop legends, showcasing her ability to thrive in creative and dynamic environments.",
            "Professionally, Cristina’s expertise lies in successfully creating and implementing strategic operational processes, mentoring and developing high-performing employees, and managing business development efforts. Her extensive experience has made her adept at overseeing a wide variety of operational responsibilities to ensure outstanding customer service and optimize financial performance.",
            "At Kovexa, Cristina advises several early-stage companies, helping them establish their operational management systems, including financial accounting packages, CRM systems, and marketing outbound lead generation tools. Her role as VP of Operations involves overseeing all strategic initiatives and client programs for the company. She has designed and custom-built Kovexa’s client database, payroll system, and lead generation systems using the latest cloud and machine learning technologies.",
            "Beyond her professional achievements, Cristina is known for her adventurous spirit. Every year, she faces her fear of heights by challenging herself to adventures that push her limits and help her grow more confident. This determination to grow and face challenges head-on is reflected in her work at Kovexa. She also brings a sense of fun to the workplace. She is often known as the prankster of the Kovexa Ladies. Her ability to blend empathy, methodical thinking, and a touch of humor makes her an effective and beloved leader."
        ] 
    },
    {   
        id: "nina-kim", 
        name: "Nina Kim", 
        role: "Director of Operations", 
        linkedin: "https://www.linkedin.com/in/nina-kim-5775a5124/",
        image: nina, 
        mainPara: "Nina Kim’s journey to becoming the Director of Operations at Kovexa is a testament to her resilience and dedication. Her path was profoundly shaped by a personal tragedy in 2020, when she took on the responsibility of caring for her niece and nephew, both of whom are autistic. This experience provided Nina with invaluable insights into how different minds work, learn, and communicate, reinforcing her passion for helping others.",
        otherDetails: [
            "I never knew what my true passion was except that I wanted to help people. I am grateful knowing that I am a small part of a larger mission to help the world in education.",
            // "Nina Kim’s journey to becoming the Director of Operations at Kovexa is a testament to her resilience and dedication. Her path was profoundly shaped by a personal tragedy in 2020, when she took on the responsibility of caring for her niece and nephew, both of whom are autistic. This experience provided Nina with invaluable insights into how different minds work, learn, and communicate, reinforcing her passion for helping others.",
            "Professionally, Nina has a robust background in operations management. Joining Kovexa has been a career highlight for her, as she finds herself working with a dedicated, passionate, and mission-driven team. Her ability to implement efficient processes ensures the smooth and effective running of the company, making her an indispensable part of Kovexa.",
            "Nina is rational, empathetic, and driven by a profound passion to help others. She prides herself on being optimistic, always striving to see the best in every situation and in everyone she encounters. Her logical, analytical, and organized approach, combined with her fairness and dedication, ensures that Kovexa consistently delivers high-quality results.",
            "Nina’s story is one of perseverance, leadership, and a deep commitment to helping others, embodying the very mission of Kovexa to improve the world through education. She is excited to learn more, grow more, and continue making a positive impact both in her career and in the lives of those around her."
        ] 
    },
    {   
        id: "katie-halecky", 
        name: "Katie Halecky", 
        role: "Director of Marketing", 
        linkedin: "https://www.linkedin.com/in/katherine-halecky-1a2a3b4c5d/",
        image: katie, 
        mainPara: "Katie Halecky's journey with Kovexa began in September 2022. Before transitioning to the marketing world, she built a strong foundation in the culinary arts and industry, working from 2007 to 2021. She earned her Bachelor's degree in Culinary Arts and Management in 2007, complemented by an Associate's degree in Culinary Arts/Chef Training in 2006, both from The Culinary Institute of America. These experiences honed her creativity and strategic thinking.",
        otherDetails: [
            "My passion for making meaningful connections and fostering growth drives everything I do. Influenced by my mother and grandmother, both educators, I have a deep love for reading and storytelling. At Kovexa, I see an incredible opportunity to leverage these passions to drive impactful change in education.",
            // "Katie Halecky's journey with Kovexa began in September 2022. Before transitioning to the marketing world, she built a strong foundation in the culinary arts and industry, working from 2007 to 2021. She earned her Bachelor's degree in Culinary Arts and Management in 2007, complemented by an Associate's degree in Culinary Arts/Chef Training in 2006, both from The Culinary Institute of America. These experiences honed her creativity and strategic thinking.",
            "Since joining Kovexa, she has utilized her extensive skills in project management and communication to elevate Kovexa’s brand. Her strategic vision and creativity have significantly contributed to the company's marketing efforts, making her an invaluable part of the team.",
            "Katie cherishes her role as a mother to two wonderful children and her journey with her husband, whom she met during her Culinary School days. Their adventures have taken them from the vibrant shores of California to the serene landscapes of Tennessee. An avid bookdragon, Katie thrives on coffee and enjoys baking with her family. Her passion for reading, engaging with people, and storytelling is deeply influenced by her strong family background in education, with both her mother and grandmother being dedicated educators. This legacy has instilled in her a profound respect for learning and growth, values that she brings to her role at Kovexa, driving her commitment to making a positive impact in education."
        ] 
    },
];

export default TEAM_DATA;