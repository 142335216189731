import React from 'react'

export default function BlogData5() {
  return (
    <section className="blog-content">
        <p>I co-authored an article for NASBE with Kristen Amundson called “The Role of Technology in Reimagining School” to provide current insight into:</p>
        <p>How well have students been served by remote learning, and are there significant gaps between groups?</p>
        <p>Can technology help reduce disparities in student learning?</p>
        <p>How can technology help teachers personalize learning?</p>
        <p>How can technology keep teachers and students safe when they return to school?</p>
        <p>Are students and educators safe in a digital learning environment?</p>
        <p>It appears in the May 2021 National Association of State Board of Education journal and can be read in its entirety <a href='https://nasbe.nyc3.digitaloceanspaces.com/2021/05/Amundson-Ko_May-2021-Standard.pdf' target='_blank' rel='noreferrer'>here</a>.</p>
    </section>
  )
}
