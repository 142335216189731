import React, { useState } from 'react';
import Navbar from './shared/Navbar';
import Footer from './shared/Footer';
import { ZAP_CONTACT_FORM_API_URL } from '../conf';
import ConfirmDialog from './shared/ConfirmDialog';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phonenumber: '',
    message: '',
    marketing_consent: false
  });

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox' && name === 'marketing_consent') {
      setFormData({
        ...formData,
        marketing_consent: checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(ZAP_CONTACT_FORM_API_URL, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(formData)
    });
    const resp = await response.json();
    if (resp) {
      setShowModal(true); // Show the success modal
      setSuccessMessage('Thank you for your interest. Someone will reach out shortly.');
      // Clear the form
      setFormData({
        firstname: '',
        lastname: '',
        email: '',
        phonenumber: '',
        message: '',
        marketing_consent: false
      });
    } else {
      setErrorMessage('Error: Something went wrong. Please try again later.');
    }
  };
  return (
    <div className="main">
      <div className="container custom-container">
        <Navbar />
        <div className="join-us-form-container">
          <h1>Connect with Us</h1>
          <p>
            Have questions or ideas to share? Reach out to us to explore how we can collaborate and drive educational innovation together. We’re here to listen and help you achieve your goals.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="firstname">First Name <span>*</span></label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your First Name"
                id="firstname"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastname">Last Name <span>*</span></label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter your Last Name"
                id="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email <span>*</span></label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter your Email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phonenumber">Phone Number <span>*</span></label>
              <input
                type="phoneNo"
                className="form-control"
                placeholder="Enter your Phone Number"
                id="phonenumber"
                name="phonenumber"
                value={formData.phonenumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control text-area"
                placeholder="Any Messages or questions"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="marketing_consent"
                name="marketing_consent"
                checked={formData.marketing_consent}
                onChange={handleChange}
              />
              <label className="custom-control-label confirm-checkbox-label" htmlFor="marketing_consent">
                Subscribe to our newsletter for interesting updates in Technology and Innovation
              </label>
            </div>
            <div className="button-div">
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </form>
          {errorMessage && <p className='error_msg'>{errorMessage}</p>}
        </div>
        <Footer />
      </div>
      {showModal && (
        <ConfirmDialog successMessage={successMessage} setShowModal={setShowModal} />
      )}
      <div className='linear-grade1'></div>
      <div className='linear-grade2'></div>
      <div className='linear-grade3'></div>
      <div className='linear-grade4'></div>
    </div>
  );
};

export default ContactUs;