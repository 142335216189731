import React from 'react';
import logo from "../../assets/logo.png"

const Navbar = ({ pageName }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-transparent">
      <a className="navbar-brand" href="/"><img src={logo} alt="KOVEXA" className="navbar-logo" /></a>
      <div className="navbar-collapse-wrapper">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <a className={`nav-link ${pageName === 'home' ? 'active' : ''}`} href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${pageName === 'offerings' ? 'active' : ''}`} href="/offerings">Offerings</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${pageName === 'news' ? 'active' : ''}`} href="/news">News</a>
            </li>
            <li className="nav-item">
              <a  className={`nav-link ${pageName === 'luminaries' ? 'active' : ''}`} href="/luminaries">Luminaries</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${pageName === 'about' ? 'active' : ''}`} href="/our-team">Our Team</a>
            </li>
          </ul>
        </div>
      </div>
      <a className="btn nav-btn" href="/join-us">Get Started Today!</a>
    </nav>
  );
};

export default Navbar;