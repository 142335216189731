import React, { useState } from 'react';
import Navbar from './shared/Navbar';
import Footer from './shared/Footer';

const AddBlog = () => {
    const [formData, setFormData] = useState({
        title: '',
        content: '',
        sub_heading: '',
        sub_heading_content: '',
        contentFile: null,
        subHeadingContentFile: null
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0]
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        // Handle form submission logic
    };
    return (
        <div className="main">
            <div className="container custom-container">
                <Navbar />
                <div className="join-us-form-container">
                    <h1>Add Blog</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="title">Title <span>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Title"
                                id="title"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="contentFile">Upload File for Blog</label>
                            <input
                                type="file"
                                className="form-control-file"
                                id="contentFile"
                                name="contentFile"
                                onChange={handleChange}
                            />
                            {/* <label className="custom-file-upload">
                                <input
                                    type="file"
                                    id="contentFile"
                                    name="contentFile"
                                    onChange={handleChange}
                                />
                                Upload File for Blog
                            </label> */}
                        </div>
                        <div className="form-group">
                            <label htmlFor="content">Content</label>
                            <textarea
                                className="form-control text-area"
                                placeholder="Add Content to Display After Title"
                                id="content"
                                name="content"
                                value={formData.content}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="sub_heading">Sub Heading <span>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Sub Heading"
                                id="sub_heading"
                                name="sub_heading"
                                value={formData.sub_heading}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="subHeadingContentFile">Upload File for Sub Heading</label>
                            <input
                                type="file"
                                className="form-control-file"
                                id="subHeadingContentFile"
                                name="subHeadingContentFile"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="sub_heading_content">Sub Heading Content</label>
                            <textarea
                                className="form-control text-area"
                                placeholder="Add Content to Display After Sub Heading"
                                id="sub_heading_content"
                                name="sub_heading_content"
                                value={formData.sub_heading_content}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        <div className="button-div">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                </div>
                <Footer />
            </div>
            <div className='linear-grade1'></div>
            <div className='linear-grade2'></div>
            <div className='linear-grade3'></div>
            <div className='linear-grade4'></div>
        </div>
    );
};

export default AddBlog;