import React from 'react'

export default function BlogData4() {
  return (
    <section className="blog-content">
        <p>In April 2020, according to UNESCO, over 1.6 billion students across 190 countries stopped attending school because of COVID. I witnessed the challenges that all educators, administrators, and government leaders were facing around the world.  Strategies for the continuation of learning were haphazard at best. It was daunting when the shutdown first occurred, and we are still a long way from figuring out how education will emerge from this change. Not one school, district, state, or country could say they got it right. This was particularly frustrating for me having spent the last 25 years evangelizing the use of technology in education through my role in the technology sector and my service as a member of the State Board of Education for the Commonwealth of Virginia.</p>
        <p>This also became personal as I dealt with COVID’s impact on learning in my own family. I received a “sorry” letter from our school district stating there was nothing they could do to help my child, who has an IEP, through distance learning.  It turns out there are no established standards for distance learning for children with special needs. My only glimmer of hope came when the four educators working with my son read the disappointing letter via google meet, but quickly said, “We officially have to read that to you, but we’re going to try everything to help him…” and they spent a lot of time with him. I knew it wasn’t going to help and sadly, it didn’t.  However, this experience made me realize there are some incredible folks dealing with life adjustments, elderly parents, and young kids all while still fighting for my child. These educators had passion and energy, but despite all the advanced technologies in the world, were woefully unprepared to do something basic like teaching online and doing so equitably.</p>
        <p>My only way to help was to jump all-in. Without a plan or even a clue of what I wanted to do, I voluntarily left my job and chartered a different course.  Some have said that I had my “Jerry Maguire” moment, but unlike in the movie, I didn’t have an episodic meltdown and get fired; nor did I take the goldfish. I needed to trust my knowledge and guide my direction with my own voice. So I drafted a memo for myself that led to the birth of my company, Kovexa.</p>
    </section>
  )
}
