import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './shared/Navbar';
import Footer from './shared/Footer';
import hero1 from '../assets/images/about/hero1.jpeg';
import LUMINARY_DETAILS from './shared/LuminaryDetails';
// import linkedin from "../assets/images/footer/linkedin.png";

const Luminary = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id') || null;

  const [luminary, setLuminary] = useState(null);

  useEffect(() => {
    if (id) {
      const foundLuminary = LUMINARY_DETAILS.find(luminary => luminary.id === id);
      setLuminary(foundLuminary);
    }
  }, [id]);

  return (
    <div className="main">
      <div className="container custom-container">
        <Navbar pageName={"luminaries"} />
        <section className="luminary-main-section">
          <div className="row">
            <div className="col-md-7 content-div">
                <h2>{luminary?.name}</h2>
                <p>{luminary?.mainPara}</p>
            </div>
            <div className="col-md-5 img-div">
              <img className="hero-image" src={luminary?.image} alt="Hero" />
            </div>
          </div>
        </section>
      <section className="luminary-content-section">
        {luminary?.otherDetails.map((item, index) => (
            <p>{item}</p>
        ))}
      </section> 
        <Footer />
      </div>
      <div className='linear-grade1'></div>
      <div className='linear-grade2'></div>
      <div className='linear-grade3'></div>
      <div className='linear-grade4'></div>
    </div>
  );
};

export default Luminary;