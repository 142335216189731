import blog1 from '../../assets/images/news/blogs/blog1.png';
import blog2 from '../../assets/images/news/blogs/blog2.png';
import blog3 from '../../assets/images/news/blogs/blog3.jpg';
import blog4 from '../../assets/images/news/blogs/blog4.jpg';
import blog5 from '../../assets/images/news/blogs/blog5.jpg';
import blog6 from '../../assets/images/news/blogs/blog6.jpg';



const BLOGS = [
    { 
        id: "1", 
        title: "Kovexa Ignites AI at the 2024 National Conference on Education: Insights and a Glimpse of the Future", 
        image: blog1
    },
    { id: "2", title: "From Challenges to Opportunities: Key Insights of Collaborative Digital Skilling Initiatives", image: blog2 },
    { id: "3", title: "The Buzz Around Artificial Intelligence (AI) in Education", image: blog3 },
    { id: "4", title: "The Catalyst", image: blog4 },
    { id: "5", title: "The Role of Technology in Reimagining School", image: blog5 },
    { id: "6", title: "The things that we need to do…", image: blog6 },
];

export default BLOGS;