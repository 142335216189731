import React from 'react'

export default function BlogData6() {
  return (
    <section className="blog-content">
        <p>This is a new era, a knowledge economy, in which companies like Airbnb, Uber, Google, and Amazon have forever changed the landscape through ingenuity, investment, and something called cloud technologies. The disruption of existing billion-dollar businesses in the hotel, ride service, and retail markets will never return to past methods. Ever.</p>
        <p>Consumers appreciate the vast array of choices, lower costs, and above all, the immediate satisfaction of service that we refer to as convenience. In a knowledge economy, we are the product, and all the services are now at our fingertips. Companies are constantly seeking information about each of us. They collect data related to our income level, marital status, friends, likes, dislikes, and thousands of other behaviors to shape our purchasing experience. Everything is now tailored and personalized for us. Everything, that is, except education.</p>
        <p>Our global education systems have not progressed nearly as quickly as commerce. COVID exposed this with the first attempts at online education as the pandemic emerged in early 2020. Education systems and educators were not well equipped to embrace the changing demands as there were no plans and a lot of ‘fumbling’ occurred. In one state, only three districts out of more than 100 had an operational collaboration tool successfully launched at scale when schools were closed. No one will say they got it right.</p>
        <p>I have been predicting for months that fall 2020-2021 school openings will have a similar impact. This time there are no mulligans left and it will get worse before it gets better. The tensions with parents and politicians will rise and the budget cuts this year will pale in comparison to next year unless a miraculous economic recovery occurs.  On average, about 85% of US K-12 district budgets are ear-marked for personnel and benefits. There is nothing further to reduce except staff. Furloughing and elimination of educators and support staff in the K-12 industry is a real possibility and may devastate an already fragile teaching profession. Universities will not go unscathed. Public universities rely on state funding for approximately 41% of their budgets to stay afloat and many, even prior COVID, were on thin ice financially. It’s not rocket science to know that many universities will struggle to stay financially viable in 2020 and the foreseeable future. We are not going back to the way it was in education, nor should we.</p>
        <p>The challenges in education are much deeper than technology. A big barrier to education is a lack of vision, an inadequate understanding of what good or great should look like now and in the years to come. The primary obstacle facing educators is the existence of silos that encourage the old guard mentality, which includes fear of trying to do something new and the lack of incentive to improve. Last month I presented a CIO with the opportunity to embrace a pilot that would showcase and demonstrate the use of data analysis from outside the educational system to gain an accurate assessment of student engagement, success, and retention. It was interrupted by an abrupt, “I don’t want to be the first” comment. This is a statement that one would never hear from anyone employed by the companies I have worked for, yet this mindset is common and acceptable in the world of education. There are no incentives to raise the bar, much less try experiments to evolve. Ironically, we all know the problems of our current education systems, yet we still don’t have the opportunity to make needed changes.</p>
        <p>We must stop talking about 21st-century learning and realize we are in the third decade of this century. The class of 2038 was born this year. These children will enter school within the next three to five years and enter the workforce mid-century. Start there with the vision and put a stake in the ground for 2050 learning. We have the urgency to do big things, make incredible and meaningful transformations now, and make strides each year in establishing a path forward for this generation and those to follow.</p>
        <p>The time is now to bring a new era of thinking into an institutional culture clinging to the old way of doing things. Education needs to bring a Silicon Valley attitude to a century-old system by reforming policies and pedagogies as well as partnering with people who understand how to best use the most advanced technologies in the world.</p>
        <p>This is what Kovexa is about. But it’s only the start.</p>
        <p>I believe that as interested parties are reading this blog they are nodding their heads in agreement. We all know that education must change and are wondering how we will make school and learning successful for all students given the conditions under which educators and and students will be operating. Consider this list of items that must be addressed to be prepared for education to work today:</p>
        <ol>
            <li>All students have the device that best fits their needs.</li>
            <li>All students have reliable internet access to allow them to work online.</li>
            <li>Educators develop effective learning plans to be used in class and/or delivered virtually.</li>
            <li>Schools choose the best programs, apps, and learning platforms and educators know how to use them effectively.</li>
            <li>Diversity and equity are accounted for in both technology access and learning paths.</li>
            <li>Class participation, assignments, and assessments must change to fit the learning modality.</li>
            <li>Measuring student engagement is critical to understand the learner holistically.</li>
        </ol>
        <p>What we know is that education as we have always done education WILL NO LONGER WORK.  I encourage you to become part of the Kovexa Keiretsu as we discover solutions to these issues.  Join us as we work together to find tools and methods that will work!</p>
        <p>If you are interested in learning more and becoming part of Kovexa, sign up as a volunteer.</p>
    </section>
  )
}
