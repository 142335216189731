// luminaryDetails.js
import benButtler from '../../assets/images/luminaries/Ben Butler.png';
import bruceMass from '../../assets/images/luminaries/Bruce Maas.jpg';
import cameronMcCoy from '../../assets/images/luminaries/Cameron McCoy.jpg';
import chrisDovi from '../../assets/images/luminaries/Chris Dovi.jpg';
import chrisNiehaus from '../../assets/images/luminaries/Chris Niehaus.jpg';
import edDieterle from '../../assets/images/luminaries/Ed Dieterle.jpg';
import evan from '../../assets/images/luminaries/Evan.jpg';
import francisGoh from '../../assets/images/luminaries/Francis-Goh.jpg';
import genevieveJopanda from '../../assets/images/luminaries/Genevieve Jopanda.jpg';
import gordon from '../../assets/images/luminaries/gordon.jpeg';
import gregoryWade from '../../assets/images/luminaries/Gregory Wade.jpeg';
import helenLianos from '../../assets/images/luminaries/HelenLianos.jpg';
import herritage from '../../assets/images/luminaries/Herritage.jpg';
import ireneSpero from '../../assets/images/luminaries/IreneSpero.jpg';
import jasonKatcher from '../../assets/images/luminaries/Jason Katcher.jpg';
import jeffMao from '../../assets/images/luminaries/Jeff Mao.jpg';
import oHalleran from '../../assets/images/luminaries/Jennie O_Holleran.png';
import karenJackson from '../../assets/images/luminaries/Karen Jackson.jpeg';
import kristenamundson from '../../assets/images/luminaries/kristenamundson.jpg';
import lizCannolly from '../../assets/images/luminaries/Liz Connolly.jpeg';
import lorettaEarly from '../../assets/images/luminaries/LorettaEarly.jpg';
import markKeam from '../../assets/images/luminaries/Mark Keam.jpg';
import mikeBerman from '../../assets/images/luminaries/Mike Berman.jpg';
import rajenSheth from '../../assets/images/luminaries/Rajen Sheth.jpg';
import satyaNarayanan from '../../assets/images/luminaries/Satya Narayanan.jpeg';
import sharifNijim from '../../assets/images/luminaries/Sharif Nijim.png';
import vincentQuah from '../../assets/images/luminaries/Vincent Quah.jpg';

const LUMINARY_DETAILS = [
    {   
        id: "ben-butler", 
        name: "Ben Butler", 
        image: benButtler, 
        mainPara: "With over 24 years of experience, Ben has built a career as an engineer, solutions architect, and as an information technology executive, demonstrating leadership, innovation, and expertise at Amazon Web Services (AWS), REAN Cloud, the US Air Force, and the MITRE Corporation.",
        otherDetails: [
            "He has held various global leadership roles, including as the Global Leader of AWS Executive Sponsorships Program, Ben developed and executed strategies to foster senior executive relationships and accelerate cloud adoption. Prior to that role, Ben created and served as the Global Leader of Cloud Innovation Programs at Amazon Web Services, where he founded a global customer innovation network of 11 centers at universities around the world, he was the Global Leader in Big Data and High Performance Computing at AWS. Ben also served as the VP of Business Development and Partners at REAN Cloud, an AWS Premier Partner. Ben was the 2012 AWS Public Sector Solutions Architect of the Year and was a certified AWS Solutions Architecture trainer. Ben has also served as a communications officer in the US Air Force and as a senior software systems and agile engineer for the MITRE Corporation supporting the Office of the Vice Chairman of the Joint Chiefs of Staff.",
            "Ben holds a B.S. in Systems Engineering from the University of Virginia, an M.B.A. from the Fuqua School of Business at Duke University, and an M.S. in Management Information Systems from Bowie State University."
        ] 
    },
    { 
        id: "bruce-maas", 
        name: "Bruce Maas", 
        image: bruceMass,
        mainPara: "Bruce Maas was the Emeritus Vice Provost for IT & CIO at the UW-Madison. Prior, he served as the CIO at the UW-Milwaukee. He served the University of Wisconsin System in a diversity of roles for over 39 years. He was a director & faculty member of the EDUCAUSE Leadership Institute and chair of the EDUCAUSE Midwest Regional Conference. He served on the EDUCAUSE Board and Board Chair. He was the PI for two NSF infrastructure grants while at UW-Madison. He was on the Board of Directors of Unizin and IMS Global. Bruce is a Fellow in the Information School at UW-Madison, Partnerships Evangelist at IMS Global, and advisor for the Wisconsin Cyber Threat Response Alliance, Armored Things, Research Space, and AEFIS.",
        otherDetails: []
    },
    { 
        id: "cameron-mccoy", 
        name: "Cameron McCoy", 
        image: cameronMcCoy, 
        mainPara: "Cameron J. McCoy, Ph.D., joined Shenandoah as its second provost in July 2021. As provost, Dr. McCoy is entrusted with nurturing a growing, ever-evolving community of learners and learning. He offers a unique perspective to the role, with leadership experience honed in educational administration, as well as military, nonprofits, and the private sector. Dr. McCoy is a sought-after business lecturer, presenter, student adviser and educational consultant. He is also a researcher whose work is focused on the boundary spanning functions at the intersection of university-industry-government.", 
        otherDetails: [] 
    },
    { 
        id: "chris-dovi", 
        name: "Chris Dovi", 
        image: chrisDovi, 
        mainPara: "With over a decade of experience in the field of computer science education, Chris co-founded and helped to lead CodeVA. This statewide CSforALL-aligned nonprofit organization teaches computer science and computational literacy to kids and trains teachers to teach computer science in Virginia schools. Chris's mission is to ensure that every Virginia child has access to computing literacy, which is the literacy of STEM and the key to future career opportunities.", 
        otherDetails: [
            "As CodeVA's chief policy advocate, Chris worked with state policymakers, education leaders, and national partners to redefine Virginia as a leader in computer science education. He was appointed to the Governor's STEM Advisory Board for Virginia by Governor Ralph Northam. He wrote and advocated for model legislation and policy that made Virginia the first state to mandate computer science as a compulsory subject for all students. In addition, Chris has secured more than $15 million in combined investments for computer science and STEM K-12 education in Virginia, from sources such as federal grants, corporate sponsors, foundations, and private donors."
        ] 
    },
    { 
        id: "chris-niehaus", 
        name: "Chris Niehaus", 
        image: chrisNiehaus, 
        mainPara: "Chris is a former Amazon and Microsoft executive with over 20 years of experience building and selling cloud solutions and programs globally. He is an expert at applying and scaling innovative technologies to positively disrupt education and workforce challenges. At Amazon, Chris led growth and commercialization planning for Amazon’s next generation, Artificial Intelligence-powered internal learning system, built Alexa in Education for EdTechs and Universities to adopt AWS voice services, and directed the global expansion of AWS EdStart, the leading global cloud accelerator for EdTech startups. At Microsoft, he led planning and launch operations for Microsoft Azure, Office 365, and Dynamics 365 global expansion to 15 new cloud data center regions including U.S. Government.", 
        otherDetails: [] 
    },
    { 
        id: "edward-dieterle", 
        name: "Dr. Edward Dieterle", 
        image: edDieterle, 
        mainPara: "Dr. Edward Dieterle, the Chief Learning Scientist at Kovexa, leads research efforts on innovative learning solutions grounded in learning science principles. With a career spanning nearly three decades in learning and teaching, Ed is driven by a passion for improving the daily experiences and life outcomes of students and workers through his research, teaching, scholarship, and product development.", 
        otherDetails: [
            "Prior to joining Kovexa, Ed was an Executive Director at Educational Testing Service (ETS) where he oversaw ETS’s externally funded research portfolio, R&D Consulting Services, and R&D’s merger and acquisition activities. Before joining ETS, Ed served as the Director at Summit Consulting, where he and his team of econometricians and data scientists specialized in litigation analytics and program evaluation using administrative data. Prior to that, he worked as a Senior Program Officer for Research at the Bill & Melinda Gates Foundation, developing and administering the Foundation’s personalized learning research portfolio. From 2008-2011, Ed conducted research at SRI International, with expertise in educational technology, including cross-disciplinary applications of statistical, computational, and machine learning tools. He served as a PI or co-PI on research projects for organizations such as the Department of Defense, NASA, and the Andrew Mellon Foundation.",
            "Early in his career, Ed worked as a high school chemistry teacher, an instructor at Johns Hopkins University, and a curriculum developer for Public Television and the National Park Service. Throughout his career, he has served on various advisory boards and committees, including the National Academy of Sciences’ Committee on Improving Learning with Information Technology, New York City Public School’s iZone Research Advisory Council, Microsoft Corporation’s Partners in Learning National Projects Advisory Board, and Learning Forward’s Professional Development Redesign Technical Working Group.",
            "Ed holds a doctorate in Learning and Teaching from Harvard University, a master’s degree in Technology for Educators from Johns Hopkins University, and a bachelor’s degree in Chemistry from Virginia Polytechnic Institute and State University. He resides in Washington, DC, with his wife and their two teenage boys."
        ] 
    },
    { 
        id: "elizabeth-connolly", 
        name: "Elizabeth Connolly", 
        image: lizCannolly, 
        mainPara: "A former university administrator and instructor, Elizabeth Connolly has held consultant and business development roles with multiple start-ups and early-stage innovators in ed tech and enrollment management services. Her previous organizations include Starfish Retention Solutions, ACUE (The Association of College and University Educators) and the Innovation Labs of The Myers-Briggs Company (creators of the VitaNavis platform for guiding students through pathways to success). She currently serves as Chief Partnership Officer for Thinking Nation, a nonprofit with a mission to prepare students to be college and career ready, through a combination of innovative supplemental curriculum, assessments, and professional development for teachers.", 
        otherDetails: [] 
    },
    { 
        id: "evan-louie", 
        name: "Evan Louie", 
        image: evan, 
        mainPara: "Evan Louie is a partner and founding member of HLN Holdings and Asian Pacific Joint Venture. He was appointed by Governor Sandoval and the state legislature of Nevada as Commissioner of Minority Affairs, chairing the Economic Development Subcommittee under the Department of Business and Industry. Evan built a business recognized by the Las Vegas Visitor and Convention Authority as one of three enterprises representing Las Vegas for global tourism. He has received numerous awards including commendation awards from U.S. Senators and U.S. Congressmembers. Evan has been a keynote speaker at national business conferences and associations on topics from social entrepreneurship to state policymaking. Evan represents several commercial real estate developers working on site plans, land entitlements, and legal teams for approval of commercial and mixed-use developments.", 
        otherDetails: [
            "In 2022, he was selected to speak on a panel with the White House Initiatives for Asian Americans, Native Hawaiian, and Pacific Islanders on businesses engaging with local, state, and federal agencies. His public affairs firm has represented some of the most prestigious brands ranging from companies featured in Forbes magazine, iconic industry-leading business establishments, and successful global award-winning companies. He is a prolific public speaker and a media magnet, with multiple appearances on local and national television, print, and radio. His passion is helping others achieve successful ventures and giving back to our communities."
        ] 
    },
    { 
        id: "francis-goh", 
        name: "Francis Goh", 
        image: francisGoh, 
        mainPara: "Francis Goh is the CEO of HehSed Consulting, which specializes in digital innovation, corporate strategy, and leadership development. He has been a coach and mentor at the Nanyang MBA faculty. As a serial entrepreneur, he has been a mentor and advisor to more than 100 startups and entrepreneurs. Prior to this, he was the CEO of the global HR consulting firm, Mercer.", 
        otherDetails: [
            "Francis has >20+ years of international experience in the technology and energy industries, having had numerous P&L and high-impact leadership roles with global leaders such as Accenture, SAP, HP, Microsoft, and ExxonMobil. During this time, Francis has worked across Asia, Europe, and the United States.",
            "Francis specializes in the areas of emerging solutions, breakthrough business models, and innovation. He has a proven track record in building high-performance teams and overachieving both revenue and profit targets consistently. He has a strong reputation for developing and deploying new business go-to-market strategies across sales, operations, and resource management. He establishes core innovative business models which further enhanced brand positioning and grew both topline and profits as a result. Francis is a hands-on leader with a deep solution and value-selling expertise across a broad spectrum of clients in the Asia Pacific region across industries such as Retail, Supply Chain, Oil &Gas, FSI, telco, manufacturing, and the government.",
            "Francis is passionate about customer experience, managing and coaching high-performing talent. He is also a recognized keynote speaker and is frequently invited to speak at various industry platforms.",
            "Francis holds an Honors degree in Mechanical Engineering, an MBA, and a PhD in International Management."
        ] 
    },
    { 
        id: "genevieve-jopanda", 
        name: "Genevieve Jopanda", 
        image: genevieveJopanda, 
        mainPara: "Genevieve Villafranca Jopanda carries over 20 years of operations and organizational leadership experience across non-profit, corporate, and government environments where she served as a political appointee in the past 10 years. Genevieve was a staff jockey for Assembly Bill 199 which led to its chaptering into law implementing the contributions of Filipinos during WWII into U.S. history books. Her most recent immediate impact is having served as Chief of Staff and top advisor to the elected State Treasurer. There she managed the day-to-day operations leading numerous state programs and divisions issuing grants, loans, and bonds funding California’s infrastructure in addition to the Treasury’s oversight of more than $2 trillion in transactions and an investment portfolio of over $100 billion within a typical year.", 
        otherDetails: [] 
    },
    { 
        id: "gordon-wishon", 
        name: "Gordon Wishon", 
        image: gordon, 
        mainPara: "Gordon Wishon is a 30+ year veteran of higher education administration, having served as CIO at Arizona State University, the University of Notre Dame, the Georgia Institute of Technology, and the Air Force Institute of Technology (where he completed a 20-year career in the United States Air Force). While at ASU, Gordon also served on the State of Arizona Data Governance Commission, Arizona IT Authorization Committee, and chaired the Board of Directors of the Sun Corridor Network. Gordon also served on the board of the ASU-GSV-Draper EdTech Accelerator, on various corporate customer advisory boards, and was a trustee of Ashford University. In 2004 he was selected as one of Computerworld magazine’s Premier 100 IT Leaders, and was awarded CIO magazine’s CIO100 award for innovation in information technology in 2005 and again in 2008. In 2013, Gordon was selected as one of Information Week magazine’s Top Five CIOs. In 2014 he received the EDUCAUSE Leadership Award, EDUCAUSE’s highest award. In 2017 Gordon was voted into CIO Magazine’s CIO Hall of Fame. Since retiring from ASU at the end of 2017, he has served as an advisor to several technology startups and as an independent consultant working on projects for university clients and consulting firms.", 
        otherDetails: [] 
    },
    { 
        id: "gregory-wade", 
        name: "Gregory Wade", 
        image: gregoryWade, 
        mainPara: "Gregory (Greg) Wade has served as Global Vice President at one of the world’s largest consumer electronics companies whose clients and partners included Citibank, Deutsche Bank, LVMH, BMW, Atos, and BlackBerry. He is recognized in the technology industry for building a $3B business in Asia Pacific and establishing brands throughout some of the world’s most demanding and dynamic markets. Greg is the author of “Mentorship: The Path to Supercharging Your Career” – published February 2021. He specializes in leading businesses to establish win/win strategic partnerships and realizes the benefits from new and established partnerships and alliances on a domestic, regional and global scale.", 
        otherDetails: [] 
    },
    { 
        id: "helen-lianos", 
        name: "Helen Lianos", 
        image: helenLianos, 
        mainPara: "Helen Lianos has spent her career working with schools and education technology corporations in many different countries. She has worked as a Microsoft Fellow and Master Trainer, helped to manage the Worldwide Teacher Ambassadors. She has also developed and delivered content for education technology companies and programs with a focus on thought leadership and successful educational application/integration of their products and services.", 
        otherDetails: [] 
    },
    { 
        id: "irene-spero", 
        name: "Irene Spero", 
        image: ireneSpero, 
        mainPara: "Irene Spero serves as CEO of NextChapter Consulting, LLC. She established NextChapter Consulting working with associations and organizations in the education and ed tech space. Ms. Spero served as CSO and CERO at CoSN. She was responsible for working with corporate partners, creating partnerships with nonprofit organizations, overseeing communications, and directing its policy and advocacy work. Prior to CoSN, she worked at the College Board leading a comprehensive government relations program at the federal and state level and served on the staff of the US Congressional Web-based Education Commission as part of the first comprehensive examination of how the Internet was changing education.", 
        otherDetails: [] 
    },
    { 
        id: "jason-katcher", 
        name: "Jason Katcher", 
        image: jasonKatcher, 
        mainPara: "Jason Katcher spent his career focusing on the education sector. Prior to working with Flatiron School and 2U, Jason worked at Kiddom, Dropbox, and Google. He spearheaded enterprise efforts working in tandem with universities and companies to deliver on upskilling and retraining outcomes. He helped solve the problem of enabling personalized learning by using an all-in-one operating system encompassing curriculum management, LMS, and data visualization. He believes that disruption, displacement &amp; transformation continue to impact the education and corporate landscapes where innovative companies can play a pivotal role in shaping the future of working, learning, and ultimately culture.", 
        otherDetails: [] 
    },
    { 
        id: "jeff-mao", 
        name: "Jeff Mao", 
        image: jeffMao, 
        mainPara: "Jeff Mao brings over 25 years of experience in the education sector. He is an education technology leader who provides strategic consulting services to schools and edtech companies, keynote speaking at regional, national, and international events, and professional learning workshops with a focus on education technology, 1:1 learning, and education policy. Jeff is an internationally known education technology leader.", 
        otherDetails: [] 
    },
    { 
        id: "jennie-oholleran", 
        name: "Jennie O’Holleran.", 
        image: oHalleran, 
        mainPara: "Jennie O’Holleran is the Principal at JPMO Strategies, LLC. She is a public policy and government relations expert with over 15 years of experience. She has served three Virginia governors, most recently serving as Policy Director for Governor Ralph Northam. Jennie also served as Policy Director of Education for Governor Terry McAuliffe, overseeing PreK-12 education issues.", 
        otherDetails: [] 
    },
    { 
        id: "karen-jackson", 
        name: "Karen Jackson", 
        image: karenJackson, 
        mainPara: "Karen R. Jackson serves as President of Apogee Strategic Partners, LLC a Virginia firm specializing in the development and implementation of technology and innovation strategies in information technology, cybersecurity, autonomous systems, and smart communities. She is also currently serving as the Interim Executive Director of the New College Institute (NCI). She is responsible for developing strategy and programming to advance economic and career opportunities as well as building partnerships to foster innovation and adoption of cutting edge industries in Southern Virginia.", 
        otherDetails: [] 
    },
    { 
        id: "katie-herritage", 
        name: "Katie Herritage", 
        image: herritage, 
        mainPara: "Katie Herritage, an accomplished professional with over two decades of experience, has recently retired from her role as the former Global Leader for Amazon Web Services Worldwide Customer Innovations and Acceleration Programs. While savoring a well-deserved retirement, Katie remains highly active as a strategic advisor and mentor for startups, leveraging her expertise to guide the next generation of entrepreneurs.", 
        otherDetails: [
            "Her extensive career has encompassed transformative leadership in both the public and private sectors, with a focus on helping organizations innovate and enhance the lives of students and citizens. Katie is also a dedicated advocate for empowering women in technology. In her retirement, she dedicates her time to philanthropic endeavors, such as volunteering with Habitat for Humanity and mentoring young professionals. With an academic background that includes a Bachelor of Arts degree in English from the University of South Carolina, a Master of Arts Degree in Communication and Leadership from Gonzaga University, and an Education Entrepreneurship Certificate from the University of Pennsylvania’s Graduate School of Education, Katie's impact extends far beyond her distinguished career."
        ] 
    },
    { 
        id: "kristen-amundson", 
        name: "Kristen Amundson", 
        image: kristenamundson, 
        mainPara: "Kristen Amundson has more than three decades of leadership in K12 education at the local, state, and national levels. She served as the President and CEO of the National Association of State Boards of Education. Amundson represented the 44th District in the Virginia General Assembly and was Vice-Chair of the House Democratic Caucus. Amundson was also a former teacher serving for nearly a decade on the Fairfax County, Va., School Board and was the senior vice president for external affairs in the Education Sector.", 
        otherDetails: [] 
    },
    { 
        id: "loretta-early", 
        name: "Loretta Early", 
        image: lorettaEarly, 
        mainPara: "Loretta Early has served HigherEd for over 20 years, including over 10 years as CIO and Vice President of Information Technology for a variety of universities. She served on CIO Executive Advisory Boards for Dell, Microsoft, Cisco, Salesforce, and Ellucian. She served as a NSF Small Business Innovation Grants Reviewer and was instrumental in supporting the creation of the Oklahoma Women in Technology organization. Leadership achievements include Computerworld 100 Best Places to Work in IT, InformationWeek 500 Top 50 Business Agility Award, InformationWeek Top 5 Higher Education CIOs, eMerge Americas Top Prize in Global Startup Competition, and IDC Smart Cities North America Awards Finalist.", 
        otherDetails: [] 
    },
    { 
        id: "mark-lee-keam", 
        name: "Mark Lee Keam", 
        image: markKeam, 
        mainPara: "Mark L. Keam is a mission-driven leader with three decades of management experience in business, government, political, and nonprofit organizations. In politics, Mark was elected to seven terms in the Virginia House of Delegates, and served as chair of the Higher Education Subcommittee, vice chair of the Finance Committee, and member of Courts, Commerce, Public Safety, and Agriculture Committees.", 
        otherDetails: [
            "At the federal level, Mark was a political appointee in two Presidential administrations for the International Trade Administration and the Small Business Administration. He was chief counsel to a United States Senate Assistant Leader and worked for a local and federal prosecutor and with a regulatory agency.",
            "In the private sector, Mark founded two international trading businesses and a solo consulting firm, was president of two national nonprofit organizations, and was an in-house counsel and policy advisor with a Fortune 15 technology company.",
            "Active in the community, Mark has served on numerous boards of local and national nonprofit organizations that advance his interests, including innovative higher education programs, promoting literacy, education, and lifelong learning, leadership development for youth, and fighting for the rights of immigrants and minorities. A naturalized immigrant, Mark grew up and attended schools in five countries, and holds degrees in political science and the law."
        ] 
    },
    { 
        id: "michael-berman", 
        name: "Michael Berman", 
        image: mikeBerman, 
        mainPara: "Michael Berman joined Kovexa as a senior advisor after more than twenty-five years in senior leadership roles in higher education. Most recently he was the Chief Information Officer (CIO) for California State University, the largest system of four-year universities in the US. Previously he held CIO, CTO, and similar positions at universities in California and New Jersey, as well as at a prestigious art and design college. His outstanding career was recognized in 2022 when he was awarded the EDUCAUSE Leadership Award, the most prestigious award in higher education information technology. Before assuming administrative roles, he was a tenured full professor in Computer Science. He has been a frequent presenter and writer on topics related to information technology, teaching and learning, and computer science. Michael was awarded a Ph.D. in Computer Science from Rutgers University. He has an extensive background in consulting and advisory roles for education institutions, major corporations, and technology startups. He has also been a board member for EDUCAUSE as well as for CENIC, which operates the network connecting education and research institutions in California and beyond. He also has experience as a software developer, and wrote a textbook published by Oxford University Press.", 
        otherDetails: [] 
    },
    { 
        id: "rajen-sheth", 
        name: "Rajen Sheth", 
        image: rajenSheth, 
        mainPara: "Rajen Sheth is an entrepreneur bringing AI to solving problems in education. His goal is to use technology to level the playing field in education and bring educational opportunities to more students. Rajen was VP of Google Cloud AI and Industry Solution. He focused on building products that enable enterprises to transform themselves through AI and transformative products for Google Cloud’s key industries. Rajen led the development of Android and Chrome for business and education. Rajen also helped create and lead product development for Google Apps(now known as GSuite), a full suite of communication and collaboration products for businesses that are now used by over 5 million businesses.", 
        otherDetails: [] 
    },
    { 
        id: "satya-narayanan", 
        name: "Satya Narayanan", 
        image: satyaNarayanan, 
        mainPara: "Satya Narayanan is an entrepreneur, teacher, founder, and chairman of CL Educate, one of the few listed EdTech companies with a presence in Asia, North America, and Africa. He founded well-known brands such as Career Launcher and Indus World Schools. Satya has been a mentor-investor in young start-ups directly as well as through venture funds for over two decades. He is a much sought-after advisor on policy and governance in the areas of education and entrepreneurship.", 
        otherDetails: [] 
    },
    { 
        id: "sharif-nijim", 
        name: "Sharif Nijim", 
        image: sharifNijim, 
        mainPara: "Sharif Nijim is an assistant teaching professor of IT, Analytics, and Operations in the Mendoza College of Business at the University of Notre Dame, where he teaches business analytics and IT. Previously, he has served as the Senior Director for IT Service Delivery at the University of Notre Dame’s Office of Information Technologies overseeing data stewardship, information security and compliance, learning platforms, product services, project management, and enterprise architecture. Sharif was instrumental in defining and executing a strategy to move to a cloud-centric model for delivering IT services. His main areas of focus include cloud computing, graph analytics, and enterprise architecture.", 
        otherDetails: [] 
    },
    { 
        id: "vincent-quah", 
        name: "Vincent Quah", 
        image: vincentQuah, 
        mainPara: "Vincent has been in the Education sector for the past 30+ years, of which 13 years were either in the capacity of an educator or as a solution consultant, in the area of e-learning, curriculum integration, teachers’ training, content management, and learners’ management.  He has led teams in Microsoft and Amazon Web Services bringing the best of breeds solutions to education customers across the Asia Pacific and Japan region in the last 20 years.", 
        otherDetails: [
            "In 2014, Vincent joined Amazon Web Services Asia Pacific and Japan as the Regional Head for Education, Healthcare, Not For Profit and Education to Skills Programs team. Here, he spearheaded the acceleration and transformation of the education segment by helping customers realize the potential and benefits of the cloud and delivering innovative and new solutions to meet the objectives of the customers. He worked with multiple universities where they migrated all their on premise infrastructure to AWS and with central ministries of education to build resilient digital services for their countries. In addition, Vincent also worked on workforce development programs to help countries reskill and upskill tertiary students as well as adult learners.",
            "In 2021, Vincent returned back to Microsoft to lead and transform the APAC Education business where he served as the Regional Sales Director. In this role, he helped grew the business across the portfolio and transformed the approach to sales and business development for higher impact. Vincent left Microsoft in June 2023.",
            "Vincent has spoken at international conferences that were held in Singapore, Brunei, Malaysia, Hong Kong, Thailand, India, Sri Lanka, Philippines, USA, Canada, Taiwan, Vietnam, and China. He also served as one of the international advisors to the Asian Development Bank’s digital technology council for 2 years."
        ] 
    }
];

export default LUMINARY_DETAILS;
